import type { ICartSidebarServiceConfig } from '~domain/entities/Config/IConfig';
import { normalizeConfigs } from '../utils/normalizeConfigs';

type SiteConfig = {
    perlgem: Record<string, unknown>;
    stardust: Record<string, unknown>;
    cart?: ICartSidebarServiceConfig;
};

const emptyDefaultConfig = {
    perlgem: {},
    stardust: {}
};

const defaultConfigs = {
    checkoutUrl: '/',
    displayGiftwrapItemsInCart: false,
    enableDiscountModule: false,
    enableDonations: false,
    enableEstimatedTotals: false,
    enableKitDetails: false,
    enableOfferCodeModule: false,
    enableOffersTab: false,
    enableRecommendedForYou: false,
    enableReplenishment: false,
    enabled: true,
    goShoppingUrl: '/',
    displayGiftWrapItems: true,
    displayEngravedItems: true,
    isReadOnly: false,
    itemMaxQuantity: 6,
    enableOfferCodeField: false,
    expandableEstimatedTotals: false,
    collection: {
        enabled: false,
        collectionName: 'favorites'
    },
    offersCarouselRules: [
        {
            enabled: 1,
            maxDisplayThreshold: '100',
            minDisplayThreshold: '0',
            offerTypesToShow: 'samples_step_up',
            ruleType: 'Check Next Offer',
            sortOrder: '1'
        },
        {
            enabled: 1,
            maxDisplayThreshold: '100',
            minDisplayThreshold: '0',
            offerTypesToShow: 'samples',
            ruleType: 'Check Next Offer',
            sortOrder: '2'
        }
    ]
};

export function getConfig() {
    // @ts-ignore
    const { cartConfig } = window.site;

    const {
        perlgem,
        stardust,
        cart,
        offers,
        currency,
        recommendations
        // @ts-ignore
    } = cartConfig || window.site?.config || (emptyDefaultConfig as SiteConfig);

    const normalizedCartConfig = normalizeConfigs({
        ...cart,
        ...offers,
        ...currency,
        ...recommendations
    }) as ICartSidebarServiceConfig;

    const siteConfig = {
        // @ts-ignore
        ...window.site?.config,
        ...defaultConfigs,
        ...normalizedCartConfig,
        perlgem,
        stardust
    };

    return {
        ...siteConfig,
        ...siteConfig.cart
    };
}
